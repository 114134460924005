export const BatchUploadHeaderText = () => {
  return (
    <div className="cursor-default">
      <p>Validate or Enrich multiple records using Trestle’s Batch Upload.</p>
      <p>Steps:</p>
      <ol type="1" className="list-decimal ml-[30px] text-start mt-[5px]">
        <li>Select the Product</li>
        <li>
          Upload the CSV file with up to 10,000 records following the templates
          {""}
          <span
            className="!underline !text-[#0494B4] !cursor-pointer ml-[5px] font-bold"
            onClick={() =>
              window.open(
                "https://trestleiq.com/knowledge-base/batch-upload-instructions/"
              )
            }
          >
            provided.
          </span>
        </li>
        <li>
          The results will be emailed to this account email address within an
          hour.
        </li>
      </ol>
    </div>
  );
};
