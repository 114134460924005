import styled from "@emotion/styled";

export const MainHeaderText = styled.div`
  font-family: DM Sans !important;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  color: #163545;
  text-align: Center;

  @media (max-width: 640px) {
    text-align: left;
  }
`;

export const BrandingPageChildContainer = styled.div`
  font-family: DM Sans !important;
  margin: auto;
  width: 100%;
  padding: 50px 140px;

  @media (max-width: 1300px) {
    padding: 30px 30px;
  }
`;

export const ModeButton = styled.div<{ selected: boolean }>`
  font-family: DM Sans !important;
  border-bottom: 8px solid transparent;
  border-color: ${(props: any) => (props.selected ? "#0494b4" : "transparent")};
  padding: 5px 0px 12px 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: ${(props: any) => (props.selected ? "500" : "400")};
  color: ${(props: any) => (props.selected ? "#ffffff" : "#8B9AA3")};
`;

export const ComponentContainer = styled.div`
  font-family: DM Sans !important;
  height: calc(100vh - 90px) !important;
  overflow-x: hidden;
  background-color: #f5f5f5;
  width: 100%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProfileMenuText = styled.div<{ w: string }>`
  font-family: DM Sans !important;
  font-weight: 700;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: ${(props: any) => (props.w ? props.w : "60px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  height: 25px;
`;

export const UserNameText = styled.div<{ w: string }>`
  font-family: DM Sans !important;
  font-weight: 500;
  font-size: 24px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: ${(props: any) => (props.w ? props.w : "60px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
  height: 25px;
`;

export const UserDetailSubHeader = styled.div`
  font-family: DM Sans !important;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  margin-bottom: 25px;
`;

export const ComponentLabelHeader = styled.div`
  font-family: DM Sans !important;
  font-size: 28px;
  color: #163545;
  font-weight: 500;
`;

export const APIKEY_LABEL = styled.div`
  font-family: DM Sans !important;
  font-size: 16px;
  font-weight: 500;
`;

export const TextLabel = styled.div`
  font-family: DM Sans !important;
  font-size: 16px;
  font-weight: 400;
`;

export const TextValue = styled.div`
  font-family: DM Sans !important;
  font-size: 16px;
  font-weight: 700;
`;

export const OldPortalDiv = styled.div`
  font-family: DM Sans !important;
  background-color: rgba(229, 244, 247, 1);
  width: -webkit-fill-available;
  position: absolute;
  text-align: center;
  line-height: 70px;
  height: 70px;
  display: flex;
`;

export const JasonResponseContainer = styled.div<{ w: string; h: string }>`
  width: ${(props: any) => (props.w ? props.w : "100%")};
  max-height: ${(props: any) => (props.h ? props.h : "100%")};
  height: fit-content;
  overflow-y: auto;
  background: #163545;
  position: relative;
  max-width:"1000px"

  ::-webkit-scrollbar {
    width: 8px !important;
    border-radius: 4px !important;
    background-color: #00485a !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: "inset 0 0 1px #006D88" !important;
    border-radius: 4px !important;
    background-color: #00485a !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #006d88 !important;
    border-radius: 4px !important;
  }
`;

export const WalletWarningText = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${(props: any) => (props.c ? props.c : "#F78331")};
`;

export const WalletBalanceText = styled.div`
  font-family: DM Sans !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
`;

export const Text14pxMedium = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const Text14pxBold = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const Text16pxBold = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const Text16pxMedium = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const Text16px = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 400;
  font-size: 16px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const Text20pxMedium = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const Text20pxBold = styled.div<{ c?: string }>`
  font-family: DM Sans !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${(props: any) => (props.c ? props.c : "#555555")};
`;

export const BannerComponentDiv = styled.div`
  display: flex;
  align-items: center;
  font-family: DM Sans !important;
  background-color: rgba(229, 244, 247, 1);
  width: -webkit-fill-available;
  text-align: center;
  line-height: 70px;
  height: 80px;
  display: flex;
  margin-top: -45px;
  padding: 0 50px;
  justify-content: space-between;
`;
